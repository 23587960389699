import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { ResponseModels_Customers_Experiments_ExperimentResponseModel } from '@monorepo-types/dc';
import { ResponseModels_Customers_Experiments_ExperimentResponseModel_List } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type GetExperiments = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  projectId: string;
};
/**
 * GetExperiments
 * Retrieves a collection of experiments
 * @returns ResponseModels_Customers_Experiments_ExperimentResponseModel_List Success
 */
export function getExperiments(
  { businessUnit, label, projectId }: GetExperiments,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Experiments_ExperimentResponseModel_List> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/experiments`,
      query: { projectId },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetExperimentById = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  experimentId: string;
};
/**
 * GetExperimentById
 * Retrieves an experiment based on its identifier
 * @returns ResponseModels_Customers_Experiments_ExperimentResponseModel Success
 */
export function getExperimentById(
  { businessUnit, label, experimentId }: GetExperimentById,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Experiments_ExperimentResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/experiments/${experimentId}`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetExperimentFeatures = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  projectId: string;
};
/**
 * GetExperimentFeatures
 * Retrieves a collection of experiments
 * @returns ResponseModels_Customers_Experiments_ExperimentResponseModel_List Success
 */
export function getExperimentFeatures(
  { businessUnit, label, projectId }: GetExperimentFeatures,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Experiments_ExperimentResponseModel_List> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/experiments/features`,
      query: { projectId },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type GetExperimentByFeatureId = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  featureId: string;
};
/**
 * GetExperimentByFeatureId
 * Retrieves an experiment based on its identifier
 * @returns ResponseModels_Customers_Experiments_ExperimentResponseModel Success
 */
export function getExperimentByFeatureId(
  { businessUnit, label, featureId }: GetExperimentByFeatureId,
  requestConfig: ApiRequestConfig = {},
): Promise<ResponseModels_Customers_Experiments_ExperimentResponseModel> {
  return request(
    {
      method: 'GET',
      path: `/dxpweb/public/${businessUnit}/${label}/experiments/features/${featureId}`,
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}
