import { createDCHook, collapseDataFromCall } from '../client';
import {
  getExperiments,
  getExperimentById,
  getExperimentFeatures,
  getExperimentByFeatureId,
} from '../services/ExperimentsService';

export const useExperimentsGetExperiments = createDCHook('getExperiments', collapseDataFromCall(getExperiments), {
  injectables: ['label', 'businessUnit'],
  flattenData: true,
});

export const useExperimentsGetExperimentById = createDCHook(
  'getExperimentById',
  collapseDataFromCall(getExperimentById),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useExperimentsGetExperimentFeatures = createDCHook(
  'getExperimentFeatures',
  collapseDataFromCall(getExperimentFeatures),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useExperimentsGetExperimentByFeatureId = createDCHook(
  'getExperimentByFeatureId',
  collapseDataFromCall(getExperimentByFeatureId),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);
